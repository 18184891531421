import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import Oidc from 'oidc-client';

@Component({})
export default class SilentRenewCallback extends Vue {

    public render(): any {
        return null;
    }

    public created(): void {
        const url = this.$route.fullPath;
        const hasTagDelimeter = '#';
        const useHastag = url.includes(hasTagDelimeter);

        const mgr = new Oidc.UserManager({
            userStore: new Oidc.WebStorageStateStore({ store: Vue.prototype.$security.getCookieStorage() }),
            loadUserInfo: false,
            filterProtocolClaims: false,
        });

        mgr.signinSilentCallback();
    }
}
